import { INT_MAX_VALUE } from '@/helpers';
import EstimateWorkflows from './EstimateWorkflows';

export default class EstimateSections {
	static roofingQuestionnaire = 1;
	static roofingDetails = 2;
	static roofingDeckUnderlayment = 3;
	static roofingFlashing = 4;
	static roofingChimneys = 5;
	static roofingSkylight = 6;
	static roofingVentilation = 7;
	static roofingFlatRoof = 8;
	static roofingExtras = 9;
	static roofingPackage = 10;
	static roofingNotes = 18;
	static roofingDiscount = 19;
	static roofingFascia = 146;
	static roofingSoffit = 147;

	static sidingQuestionnaire = 21;
	static sidingDetails = 22;
	static sidingHouseWrap = 23;
	static sidingAccessories = 24;
	static sidingCorners = 25;
	static sidingWraps = 26;
	static sidingTrim = 27;
	static sidingShutters = 28;
	static sidingAccents = 29;
	static sidingDoors = 30;
	static sidingExtras = 31;
	static sidingPackage = 32;
	static sidingNotes = 38;
	static sidingDiscount = 39;
	static sidingPainting = 141;
	static sidingFascia = 144;
	static sidingSoffit = 145;

	static windowQuestionnaire = 41;
	static windowList = 42;
	static windowTrim = 45;
	static windowProjectSpecs = 43;
	static windowPackage = 44;
	static windowNotes = 58;
	static windowDiscount = 59;

	static gutterDetails = 61;
	static gutterGutters = 66;
	// static gutterSoffit = 62;
	// static gutterFascia = 64;
	// static gutterFriezeBoard = 65;
	static gutterExtras = 63;
	static gutterNotes = 78;
	static gutterDiscount = 79;

	static trimDetails = 121;
	static trimSoffit = 122;
	static trimFascia = 123;
	static trimComposite = 124;
	static trimWraps = 126;
	static trimExtras = 125;
	static trimNotes = 138;
	static trimDiscount = 139;
	static trimPainting = 142;

	static repairRoof = 81;
	static repairFlashing = 82;
	static repairChimney = 83;
	static repairFlatRoof = 84;
	static repairSkylight = 85;
	static repairVentilation = 86;
	static repairExtras = 87;
	static repairNotes = 98;
	static repairDiscount = 99;
	static repairPainting = 143;

	static insulationQuestionnaire = 101;
	static insulationPreTest = 102;
	static insulationAirSeal = 103;
	static insulationDetails = 104;
	static insulationExtras = 105;
	static insulationNotes = 118;
	static insulationDiscount = 119;

	static map = {
		// general
		// roofing
		[this.roofingNotes]: { id: this.roofingNotes, workflowId: EstimateWorkflows.roofing, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.roofingDiscount]: { id: this.roofingDiscount, workflowId: EstimateWorkflows.roofing, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.roofingQuestionnaire]: { id: this.roofingQuestionnaire, workflowId: EstimateWorkflows.roofing, order: 1, name: 'Questionnaire', isNotes: false, isDiscount: false, },
		[this.roofingDetails]: { id: this.roofingDetails, workflowId: EstimateWorkflows.roofing, order: 2, name: 'Roof details', isNotes: false, isDiscount: false, },
		[this.roofingDeckUnderlayment]: { id: this.roofingDeckUnderlayment, workflowId: EstimateWorkflows.roofing, order: 3, name: 'Decking & Underlayment', isNotes: false, isDiscount: false, },
		[this.roofingFlashing]: { id: this.roofingFlashing, workflowId: EstimateWorkflows.roofing, order: 4, name: 'Flashing', isNotes: false, isDiscount: false, },
		[this.roofingChimneys]: { id: this.roofingChimneys, workflowId: EstimateWorkflows.roofing, order: 5, name: 'Chimneys', isNotes: false, isDiscount: false, },
		[this.roofingSkylight]: { id: this.roofingSkylight, workflowId: EstimateWorkflows.roofing, order: 6, name: 'Skylight', isNotes: false, isDiscount: false, },
		[this.roofingVentilation]: { id: this.roofingVentilation, workflowId: EstimateWorkflows.roofing, order: 7, name: 'Ventilation', isNotes: false, isDiscount: false, },
		[this.roofingFlatRoof]: { id: this.roofingFlatRoof, workflowId: EstimateWorkflows.roofing, order: 8, name: 'Flat roofing', isNotes: false, isDiscount: false, },
		[this.roofingExtras]: { id: this.roofingExtras, workflowId: EstimateWorkflows.roofing, order: 11, name: 'Extras', isNotes: false, isDiscount: false, },
		[this.roofingPackage]: { id: this.roofingPackage, workflowId: EstimateWorkflows.roofing, order: 12, name: 'Packages', isNotes: false, isDiscount: false, },
		[this.roofingSoffit]: { id: this.roofingSoffit, workflowId: EstimateWorkflows.roofing, order: 9, name: 'Soffit', isNotes: false, isDiscount: false, },
		[this.roofingFascia]: { id: this.roofingFascia, workflowId: EstimateWorkflows.roofing, order: 10, name: 'Fascia', isNotes: false, isDiscount: false, },
		// siding
		[this.sidingNotes]: { id: this.sidingNotes, workflowId: EstimateWorkflows.siding, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.sidingDiscount]: { id: this.sidingDiscount, workflowId: EstimateWorkflows.siding, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.sidingQuestionnaire]: { id: this.sidingQuestionnaire, workflowId: EstimateWorkflows.siding, order: 1, name: 'Questionnaire', isNotes: false, isDiscount: false, },
		[this.sidingDetails]: { id: this.sidingDetails, workflowId: EstimateWorkflows.siding, order: 2, name: 'Details', isNotes: false, isDiscount: false, },
		[this.sidingHouseWrap]: { id: this.sidingHouseWrap, workflowId: EstimateWorkflows.siding, order: 3, name: 'House Wrap', isNotes: false, isDiscount: false, },
		[this.sidingAccessories]: { id: this.sidingAccessories, workflowId: EstimateWorkflows.siding, order: 4, name: 'Accessories', isNotes: false, isDiscount: false, },
		[this.sidingCorners]: { id: this.sidingCorners, workflowId: EstimateWorkflows.siding, order: 5, name: 'Corners', isNotes: false, isDiscount: false, },
		[this.sidingWraps]: { id: this.sidingWraps, workflowId: EstimateWorkflows.siding, order: 6, name: 'Wraps', isNotes: false, isDiscount: false, },
		[this.sidingTrim]: { id: this.sidingTrim, workflowId: EstimateWorkflows.siding, order: 9, name: 'Trim', isNotes: false, isDiscount: false, },
		[this.sidingShutters]: { id: this.sidingShutters, workflowId: EstimateWorkflows.siding, order: 10, name: 'Shutters', isNotes: false, isDiscount: false, },
		[this.sidingAccents]: { id: this.sidingAccents, workflowId: EstimateWorkflows.siding, order: 11, name: 'Accents', isNotes: false, isDiscount: false, },
		[this.sidingDoors]: { id: this.sidingDoors, workflowId: EstimateWorkflows.siding, order: 12, name: 'Doors', isNotes: false, isDiscount: false, },
		[this.sidingExtras]: { id: this.sidingExtras, workflowId: EstimateWorkflows.siding, order: 14, name: 'Extras', isNotes: false, isDiscount: false, },
		[this.sidingPackage]: { id: this.sidingPackage, workflowId: EstimateWorkflows.siding, order: 15, name: 'Package', isNotes: false, isDiscount: false, },
		[this.sidingPainting]: { id: this.sidingPainting, workflowId: EstimateWorkflows.siding, order: 13, name: 'Paint', isNotes: false, isDiscount: false, },
		[this.sidingSoffit]: { id: this.sidingSoffit, workflowId: EstimateWorkflows.siding, order: 7, name: 'Soffit', isNotes: false, isDiscount: false, },
		[this.sidingFascia]: { id: this.sidingFascia, workflowId: EstimateWorkflows.siding, order: 8, name: 'Fascia', isNotes: false, isDiscount: false, },
		// windows
		[this.windowNotes]: { id: this.windowNotes, workflowId: EstimateWorkflows.windows, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.windowDiscount]: { id: this.windowDiscount, workflowId: EstimateWorkflows.windows, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.windowQuestionnaire]: { id: this.windowQuestionnaire, workflowId: EstimateWorkflows.windows, order: 1, name: 'Questionnaire', isNotes: false, isDiscount: false, },
		[this.windowList]: { id: this.windowList, workflowId: EstimateWorkflows.windows, order: 2, name: 'Add windows', isNotes: false, isDiscount: false, },
		[this.windowTrim]: { id: this.windowTrim, workflowId: EstimateWorkflows.windows, order: 3, name: 'Trim', isNotes: false, isDiscount: false, },
		[this.windowProjectSpecs]: { id: this.windowProjectSpecs, workflowId: EstimateWorkflows.windows, order: 4, name: 'Project specs', isNotes: false, isDiscount: false, },
		[this.windowPackage]: { id: this.windowPackage, workflowId: EstimateWorkflows.windows, order: 5, name: 'Packages', isNotes: false, isDiscount: false, },
		// gutters
		[this.gutterNotes]: { id: this.gutterNotes, workflowId: EstimateWorkflows.gutters, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.gutterDiscount]: { id: this.gutterDiscount, workflowId: EstimateWorkflows.gutters, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.gutterDetails]: { id: this.gutterDetails, workflowId: EstimateWorkflows.gutters, order: 1, name: 'Details', isNotes: false, isDiscount: false, },
		[this.gutterGutters]: { id: this.gutterGutters, workflowId: EstimateWorkflows.gutters, order: 2, name: 'Gutters', isNotes: false, isDiscount: false, },
		[this.gutterExtras]: { id: this.gutterExtras, workflowId: EstimateWorkflows.gutters, order: 3, name: 'Extras', isNotes: false, isDiscount: false, },
		// trim
		[this.trimNotes]: { id: this.trimNotes, workflowId: EstimateWorkflows.trim, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.trimDiscount]: { id: this.trimDiscount, workflowId: EstimateWorkflows.trim, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.trimDetails]: { id: this.trimDetails, workflowId: EstimateWorkflows.trim, order: 1, name: 'Details', isNotes: false, isDiscount: false, },
		[this.trimSoffit]: { id: this.trimSoffit, workflowId: EstimateWorkflows.trim, order: 2, name: 'Soffit', isNotes: false, isDiscount: false, },
		[this.trimFascia]: { id: this.trimFascia, workflowId: EstimateWorkflows.trim, order: 3, name: 'Fascia', isNotes: false, isDiscount: false, },
		[this.trimComposite]: { id: this.trimComposite, workflowId: EstimateWorkflows.trim, order: 4, name: 'Composite', isNotes: false, isDiscount: false, },
		[this.trimWraps]: { id: this.trimWraps, workflowId: EstimateWorkflows.trim, order: 5, name: 'Wraps', isNotes: false, isDiscount: false, },
		[this.trimExtras]: { id: this.trimExtras, workflowId: EstimateWorkflows.trim, order: 7, name: 'Extras', isNotes: false, isDiscount: false, },
		[this.trimPainting]: { id: this.trimPainting, workflowId: EstimateWorkflows.trim, order: 6, name: 'Paint', isNotes: false, isDiscount: false, },
		// repairs
		[this.repairRoof]: { id: this.repairRoof, workflowId: EstimateWorkflows.repairs, order: 1, name: 'Roof', isNotes: false, isDiscount: false },
		[this.repairFlashing]: { id: this.repairFlashing, workflowId: EstimateWorkflows.repairs, order: 2, name: 'Flashing', isNotes: false, isDiscount: false },
		[this.repairChimney]: { id: this.repairChimney, workflowId: EstimateWorkflows.repairs, order: 3, name: 'Chimney', isNotes: false, isDiscount: false },
		[this.repairFlatRoof]: { id: this.repairFlatRoof, workflowId: EstimateWorkflows.repairs, order: 4, name: 'Flat Roof', isNotes: false, isDiscount: false },
		[this.repairSkylight]: { id: this.repairSkylight, workflowId: EstimateWorkflows.repairs, order: 5, name: 'Skylight', isNotes: false, isDiscount: false },
		[this.repairVentilation]: { id: this.repairVentilation, workflowId: EstimateWorkflows.repairs, order: 6, name: 'Ventilation', isNotes: false, isDiscount: false },
		[this.repairExtras]: { id: this.repairExtras, workflowId: EstimateWorkflows.repairs, order: 8, name: 'Extras', isNotes: false, isDiscount: false },
		[this.repairNotes]: { id: this.repairNotes, workflowId: EstimateWorkflows.repairs, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.repairDiscount]: { id: this.repairDiscount, workflowId: EstimateWorkflows.repairs, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.repairPainting]: { id: this.repairPainting, workflowId: EstimateWorkflows.repairs, order: 7, name: 'Paint', isNotes: false, isDiscount: false, },
		// insulation
		[this.insulationNotes]: { id: this.insulationNotes, workflowId: EstimateWorkflows.insulation, order: 0, name: 'Notes', isNotes: true, isDiscount: false, },
		[this.insulationDiscount]: { id: this.insulationDiscount, workflowId: EstimateWorkflows.insulation, order: 0, name: 'Discount', isNotes: false, isDiscount: true, },
		[this.insulationQuestionnaire]: { id: this.insulationQuestionnaire, workflowId: EstimateWorkflows.insulation, order: 1, name: 'Questionnaire', isNotes: false, isDiscount: false, },
		[this.insulationPreTest]: { id: this.insulationPreTest, workflowId: EstimateWorkflows.insulation, order: 2, name: 'Pre-Test', isNotes: false, isDiscount: false, },
		[this.insulationAirSeal]: { id: this.insulationAirSeal, workflowId: EstimateWorkflows.insulation, order: 3, name: 'Air Seal', isNotes: false, isDiscount: false, },
		[this.insulationDetails]: { id: this.insulationDetails, workflowId: EstimateWorkflows.insulation, order: 4, name: 'Details', isNotes: false, isDiscount: false, },
		[this.insulationExtras]: { id: this.insulationExtras, workflowId: EstimateWorkflows.insulation, order: 5, name: 'Extras', isNotes: false, isDiscount: false, },
	};

	static list = Array.from(Object.values(this.map));

	static getWorkflowId(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].workflowId;
		} else {
			return INT_MAX_VALUE;
		}
	}

	static getName(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].name;
		} else {
			return '';
		}
	}

	static getOrder(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].order;
		} else {
			return INT_MAX_VALUE;
		}
	}

	static getSectionsInWorkflow(workflowId) {
		const sections = {};
		for (const key in this.map) {
			if (Object.hasOwnProperty.call(this.map, key)) {
				const info = this.map[key];
				if (info.workflowId === workflowId) {
					sections[key] = info;
				}
			}
		}
		return sections;
	}

	static getNotesSectionId(workflowId) {
		return (this.list.find(x => x.workflowId === workflowId && x.isNotes) || { id: null }).id;
	}

	static getDiscountSectionId(workflowId) {
		return (this.list.find(x => x.workflowId === workflowId && x.isDiscount) || { id: null }).id;
	}

	static getSecondaryWorkflowId(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].secondaryWorkflowId;
		} else {
			return '';
		}
	}
}
